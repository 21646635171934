import React, {Component} from "react";
import classes from "./Author.module.css"

class Author extends Component {
    render() {
        return <div className={classes.Author}>
            <p>Made with love by <a href="https://github.com/misobarisic">Mišo Barišić</a></p>
        </div>
    }
};

export default Author;
