import * as actionTypes from "../actions/actionTypes"
import produce from "immer"

const initialState = {
    example: 0
}

const instance = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.EXAMPLE:
            return produce(state, draft => {
                draft.example++
            })
        default:
            return state
    }
}

export default instance