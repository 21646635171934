import React from "react";
import classes from "./Heading.module.css"

const Heading = (props) => (
    <div className={classes.Heading}>
        <h2>{props.heading}</h2>
    </div>
);

export default Heading;
