import React from 'react';
import classes from './App.module.css';
import RobotBuilder from "./containers/RobotBuilder";
import {Route, Switch} from "react-router-dom";

function App() {
    return (
        <div className={classes.App}>
            <Switch>
                <Route path="/" component={RobotBuilder}/>
            </Switch>
        </div>
    );
}

export default App;
