import React, {Component} from "react";
import classes from "./RobotBuilder.module.css"
import {connect} from 'react-redux';
import * as actionCreators from "../store/actions/actionCreators"
import Robot from "../components/Robot";
import Input from "../components/Input/Input";
import Author from "../components/Author/Author";
import Heading from "../components/Heading/Heading";

class RobotBuilder extends Component {
    state = {
        inputValue: null
    }


    onChangeHandler = (event) => {
        const value = event.target.value
        this.setState(!!value ? {inputValue: value} : {inputValue: null})
    }

    render() {
        let robot = <Robot
            src="Guten Tag"
            alt="Hello"
            set="set1"/>
        if (this.state.inputValue) {
            robot = <Robot
                src={this.state.inputValue}
                alt={this.state.inputValue}
                set="set1"/>
        }

        return <React.Fragment>
            <div className={classes.RobotBuilder}>
                <Heading heading="Robot Builder"/>
                <div>
                    {robot}
                    <Input
                        onChange={(event) => this.onChangeHandler(event)}/>
                </div>
            </div>
            <Author/>
        </React.Fragment>
    }
};

const mapStateToProps = state => {
    return {
        example: state.example.example
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onIngredientAdded: () => dispatch(actionCreators.example())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RobotBuilder);
