import React, {Component} from "react";
import classes from "./Input.module.css";

class Input extends Component {
    render() {
        return <div className={classes.Input}>
            <input
                value={this.props.value}
                onChange={this.props.onChange}
                placeholder="Enter a value to hash"/>
        </div>
    }
}

export default Input;
